import {backendApi} from "../../backend-api-sr"

export const report_usage = (() =>{
    const test = (() => {
        return ""
    })

    const index = ( async (subPath) => {
        var url = '/api/accounting/gl-budget/report_usage'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const store = ( async (subPath, reqBody) => {
        var url = '/api/accounting/gl-budget/report_usage'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const update = ( async (subPath, reqBody) => {
        var url = '/api/accounting/gl-budget/report_usage/update'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const destroy = ( async (subPath, reqBody) => {
        var url = '/api/accounting/gl-budget/report_usage/destroy'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getNoReff = ( async (subPath, reqBody) => {
        var url = '/api/accounting/gl-budget/report_usage/reffNumber'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {index, store, update, test, destroy, getNoReff};

})()